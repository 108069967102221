<template>
  <form class="building-form">
    <div class="input-group-lg">
      <div v-if="isFirstBuilding" class="pb-1">
        <input type="checkbox" id="copy-mailing" v-model="isCopyAddress">
        <label
          for="copy-mailing" class="ml-2"
          :class="buildingDetails.attributes.copyMailingAddress.class"
        >
          {{ buildingDetails.attributes.copyMailingAddress.text }}
        </label>
      </div>

      <validation-observer
        tag="form"
        @submit.prevent="createBuilding()"
        ref="buildingDetails"
      >
        <form-field
          :formFields="fields"
          :show="true"
          :model="currentBuildingWithExtraInfo"
          :id-prefix="idPrefix"
          :isCopyAddress="isCopyAddress"
        />

        <button
          type="submit"
          :disabled="awaitingResponse"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3"
          :class="buildingDetails.attributes[selectedButton].class"
          v-if="!disable_edit_building"
        >
          {{ buildingDetails.attributes[selectedButton].text }}
        </button>
        <button
          type="button"
          class="btn btn-lg d-flex ml-auto mr-auto mt-3"
          :class="buildingDetails.attributes.button3.class"
          @click="redirectBack(buildingDetails.attributes.button3['path-name'])"
          v-else-if="disable_edit_building && buildingDetails.attributes.button3"
        >
          {{ buildingDetails.attributes.button3.text }}
        </button>
      </validation-observer>
    </div>
  </form>
</template>

<script>
import FormField from './form/FormField.vue';
import router from '../router/index';

export default {
  name: 'BuildingFormFields',

  components: {
    FormField,
  },

  props: {
    selectedButton: {
      type: String,
      default: 'button2',
    },
  },

  data() {
    return {
      idPrefix: 'building-details',
      isCopyAddress: false,
      currentBuildingWithExtraInfo: {},
      awaitingResponse: false,
    };
  },

  created() {
    this.updateBuildingData();
  },

  computed: {
    buildingDetails() {
      return this.$store.state.partners.currentPartner.settings.sections.building_details;
    },

    currentBuilding() {
      return this.$store.state.buildings.currentBuilding;
    },

    isFirstBuilding() {
      return this.currentBuilding.id === null
        && this.$store.state.buildings.allBuilding.length === 0;
    },
    partner() {
      return this.$store.state.partners.currentPartner;
    },

    disable_edit_building() {
      const disable = this.partner.settings.disable_building_profile_edition || this.isFulFillSync || this.partner.settings.enable_sign_up_integrated;
      return disable;
    },
    fields() {
      if (this.disable_edit_building) {
        return this.buildingDetails.fields.map((field) => (
          {
            ...field,
            disabled: 'true',
          }
        ));
      }
      return this.buildingDetails.fields;
    },

    isFulFillSync() {
      return this.$store.state.users.currentUser.extra_info?.is_fulfill_sync;
    }
  },

  methods: {
    createBuilding() {
      if (!this.disable_edit_building) {
        this.$refs.buildingDetails.validate()
          .then((isValid) => {
            if (isValid) {
              // disable submit button while request resolves
              this.awaitingResponse = true;

              this.$store.dispatch('buildings/update', this.currentBuildingWithExtraInfo)
                .then((response) => {
                  this.$store.dispatch('users/updateCsvBuildingId');
                  if (response.status === 201) {
                    this.$notify(this.buildingDetails.attributes.created_notification);
                  } else {
                    this.$notify({ group: 'notice', text: response.data.message });
                  }

                  this.$emit('update-success');
                })
                .catch((error) => {
                  if (error.response) {
                    console.log(error.response.data.errors);

                    this.$notify({
                      group: 'notice',
                      text: error.response.data.errors[0].title,
                    });
                  }
                  // re-enable submit button
                  this.awaitingResponse = false;
                });
            } else {
              this.$notify({
                group: 'notice',
                text: 'Fill in all required fields.',
              });
            }
          });
      } else {
        this.$notify({
          group: 'notice',
          text: 'Building update is disabled.',
        });
      }
    },

    updateBuildingData() {
      this.currentBuildingWithExtraInfo = {
        ...this.currentBuilding,
        ...this.currentBuilding.extra_info,
      };
    },
    redirectBack(name) {
      router.push({ name });
    },
  },
};
</script>
