<template>
  <div :class="['content-box', { 'no-card': disableCard }]">
    <div :class="['card-body', { 'no-card': disableCard }]">
      <span class="egx-partner-color">
        <h2 v-html="header.title" />
      </span>
      <div v-html="header.description" />
      <applicationCard class="mt-4" />
    </div>
  </div>
</template>

<script>
import mockData from '../mockData';
import applicationCard from './applicationCard.vue';

export default {
  components: {
    applicationCard,
  },
  data() {
    return {
      header: mockData.application.header,
      disableCard: false,
    };
  },
}
</script>
