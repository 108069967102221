<template>
  <div class="prq-section col-12 py-4 pl-0">
    <Dialog :show="deleteDialogVisible" @close="$event => closeDialog()">
      <div class="dialog-box">
        <h2>Confirm</h2>
        <p>Do you want to remove this file?
          <br />
          <b>{{this.selectedAttachmentResponse?.response}}</b>
        </p>
        <div class="dialog-buttons">
          <button class="cancel-button" @click="$event => closeDialog()" :disabled="isDeleting">Cancel</button>
          <button class="confirm-button" @click="$event => deleteAttachment()" :disabled="isDeleting">
            <span v-if="!isDeleting">Yes</span>
            <font-awesome-icon
              :icon="['fa', 'circle-notch']"
              class="fa-spin m-auto spinner"
              v-else
            />
          </button>
        </div>
      </div>
    </Dialog>
    <div class="content-box row">
      <div class="col-12">
        <header>
          <h2 class="mt-3 title">
            <span class="egx-partner-color">
              {{ section.name }}
            </span>
          </h2>
          <p class="sub-title" v-html="section.message_or_instructions" />
        </header>
      </div>
      <template>
        <div class="container pb-4">
          <div v-for="attachment in attachments" :key="attachment.id">
            <div class="document-section">
              <div class="row p-3">
                <font-awesome-icon :icon="['fasdt', 'file']" class="file-icon col-12 col-md-1 egx-partner-color" />
                <div class="pl-0 pt-2 col-md-8 col-sm-12 pr-sm-0">
                  <validation-provider
                    mode="lazy"
                    :rules="addValidation(attachment)"
                    v-slot="{ errors }"
                    :ref="'al-item-' + attachment.id"
                    :custom-messages="customErrorMsg"
                  >
                    <div class="mb-0 sub-section-title text-center text-md-left">
                      {{ attachment.question }}
                      <span class="text-danger" v-if="attachment.required === 'true'">
                        *
                      </span>
                      <input :value="attachment.responses.length" disabled type="hidden" />
                      <div class="msg-error text-danger">{{ errMsg = errors[0] }}</div>
                    </div>
                  </validation-provider>
                </div>
                <div class="col-sm-12 col-md-3 upload-loading" v-if="isFileUploading(attachment.id)">
                  <font-awesome-icon
                    :icon="['fa', 'circle-notch']"
                    class="fa-spin m-auto fa-2x spinner egx-partner-color"
                  />
                </div>
                <label
                  v-else
                  class="btn btn-link upload-link col-md-3 col-sm-12 egx-partner-color text-md-right"
                  @change="addAttachment(
                    $event.target.files, attachment
                  )">
                    Upload
                  <input class="d-none" type="file" />
                </label>
              </div>
              <template v-for="response in attachment.responses">
                <div class="uploaded-files">
                  <div class="row file-section">
                    <div class="col-md-10 col-sm-12">
                      <a @click.prevent="downloadAttachment(response)" class="d-flex btn btn-link">
                        <div class="file-name doc-link mt-1 text-break">
                          {{response.response}}
                        </div>
                      </a>
                    </div>
                    <div class="col-md-2 col-sm-12 d-flex justify-content-center justify-content-md-end align-self-center pr-3 m-auto">
                      <span class="x-close egx-partner-color" @click="showDialog(attachment, response)">X</span>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import Dialog from '../Dialog.vue';

export default {
  name: 'Attachment',

  components: {
    Dialog
  },

  props: {
    section: Object,
    checkValidation: true,
  },

  data() {
    return {
      customErrorMsg: {},
      errMsg: '',
      isDeleting: false,
      deleteDialogVisible: false,
      selectedAttachment: null,
      selectedAttachmentResponse: null,
      attachments: [],
      validationList: [],
      fileUploadingList: [],
    };
  },

  created() {
    const tempQuestions = {};
    this.section.questions.forEach((attachment) => {
      if (!tempQuestions[attachment.id]){
        tempQuestions[attachment.id] =  JSON.parse(JSON.stringify(attachment));
        tempQuestions[attachment.id]['responses'] = []

        delete tempQuestions[attachment.id]['response'];
        delete tempQuestions[attachment.id]['response_2'];
        delete tempQuestions[attachment.id]['additional_response_info'];
      }

      if (attachment.response) {
        tempQuestions[attachment.id]['responses'].push({
          response: attachment.response,
          response_2: attachment.response_2,
          additional_response_info: attachment.additional_response_info,
        });
      }
    });

    this.attachments = Object.values(tempQuestions);

    this.attachments =
      this.attachments.sort(
        (a, b) => a.sorting_tag.localeCompare(b.sorting_tag, 'en', { numeric: true })
      );
  },

  computed: {
  },

  methods: {
    async addAttachment(fileList, attachment) {
      if (!fileList.length) return;

      const self = this;
      const file = fileList[0];
      this.uploadError = '';
      const formData = new FormData();

      formData.append('attachment_id', attachment.id);
      formData.append('attachment_rules', attachment.rules);
      formData.append('project_id', this.section.project_id);
      formData.append('system_id', this.section.system_id);
      formData.append('file', file);
      this.fileUploadingList.push(attachment.id);

      await this.$store.dispatch('fulfill_integrate/postAttachment', formData).then((response) => {
        response.questions.forEach((question) => {
          const find_attachment = self.attachments.find(obj => obj.id === question.id);
          find_attachment.responses.push({
            response: question.response,
            additional_response_info: question.additional_response_info,
          });
        });
      }).catch((error) => {
        this.uploadError = 'Issue uploading document. Please check the document and try again.';
        self.apiErrorMessages(error);
      }).finally(() => {
        this.fileUploadingList = this.fileUploadingList.filter(obj => obj !== attachment.id);
      });
    },

    downloadAttachment(attachment) {
      const params = {
        id: attachment.additional_response_info,
        fileName: attachment.response,
      }

      this.$store.dispatch('fulfill_integrate/getAttachment', params).then((r) => {
        console.log('Download Compelete.');
      }).catch((error) => {
        console.log(error.message);
      });
    },

    showDialog(attachment, response) {
      this.selectedAttachment = attachment;
      this.selectedAttachmentResponse= response;
      this.deleteDialogVisible = true;
    },

    closeDialog() {
      this.deleteDialogVisible = false;
      this.selectedAttachment = null;
      this.selectedAttachmentResponse= null;
    },

    deleteAttachment() {
      if (this.isDeleting) {
        return false;
      }

      this.isDeleting = true;
      const params ={
        id: this.selectedAttachment.id,
        ...this.selectedAttachmentResponse
      };

      this.$store.dispatch('fulfill_integrate/deleteAttachment', params).then((response) => {
        const find_attachment = this.attachments.find(obj => obj.id === params.id);
        find_attachment.responses = find_attachment.responses.filter(obj => obj.response !== params.response);
        this.$notify({
          group: 'notice',
          text: response.message,
        });
      }).catch((error) => {
        console.log(error.message);
        this.$notify({
          group: 'notice',
          text: error.message,
        });
      }).finally(() => {
        this.isDeleting = false;
        this.deleteDialogVisible = false;
      });
    },

    isFileUploading(attachmentId) {
      return this.fileUploadingList.includes(attachmentId);
    },

    addValidation(attachment) {
      if (attachment.required === 'true' && attachment.responses.length === 0) {
        this.customErrorMsg['min_value'] = "This upload is required.";
        return 'min_value:1';
      }

      return '';
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style lang="scss" scoped>
  @import '../../assets/stylesheets/components/project/project.scss';
  @import '../../assets/stylesheets/components/project/confirm-dialog.scss';
</style>
