<template>
<div :class="['content-box', { 'no-card': disableCard }]">
  <div class="m-2 pl-3 text-center" v-show="isLoading">
    <font-awesome-icon
      :icon="['fa', 'circle-notch']"
      class="fa-spin m-auto spinner fa-5x egx-partner-color"
    />
  </div>
  <div :class="['card-body', { 'no-card': disableCard }]" v-for="(application, index) in applications">
    <div class="row">
      <div class="col-lg-8">
        <div>
          <span class="egx-partner-color">
            <h4><router-link :to="`/applications/${application.id}`" class="egx-partner-color">{{ application.name }}</router-link></h4>
          </span>
        </div>
        <div>
          <b>Application Id: </b>
          <span>{{ application.id }}</span>
        </div>
        <div>
          <b>Status: </b>
          <span>{{ application.custom_status_description || application.status }}</span>
        </div>
        <div>
          <p>{{ application.description }}</p>
        </div>
        <div class="mt-4">
          <b>Last Updated: </b>
          <span>{{ application.custom_status_date || formatDate(application.last_modified_ts) }}</span>
        </div>
      </div>
      <div class="col-lg-4 d-flex flex-column justify-content-center align-items-center">
        <button class="btn btn-egx  mb-2" style="height: 3rem; width: 10rem;" @click="viewApplication(application.id)" v-if="settings.display_applications_view_button">View</button>
        <button class="btn btn-warning" style="height: 3rem; width: 10rem;" @click="updateApplication(application.id)">Update</button>
      </div>
    </div>
    <hr v-if="index < applications.length - 1" />
  </div>
</div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      applications: [],
      disableCard: false,
      isLoading: false
    };
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/getProjects').then(() => {
      this.applications = this.$store.state.fulfill_integrate.projects;
      this.isLoading = false;
    }).catch((error) => {
      console.log(error.message);
      this.$notify({
        group: 'notice',
        text: error.message,
      });
    });
  },
  methods: {
    updateApplication(id) {
      this.$router.push(`/applications/${id}/edit?step=1`);
    },
    viewApplication(id) {
      this.$router.push(`/applications/${id}`);
    },
    formatDate(dateString) {
      return new Date(dateString).toUTCString();
    }
  },
  computed: {
    settings() {
      return this.$store.state.partners.currentPartner.settings;
    },
  }
}
</script>

<style scoped>
.card-body {
  margin-bottom: 10px;
}
hr {
  margin-bottom : -2rem;
}
.egx-partner-color {
  color: inherit;
}
</style>
