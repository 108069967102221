<template>
  <div class="d-flex justify-content-end mt-4">
    <button class="btn btn-egx mr-4" @click="prevButtonFlow" v-if="displayPrevious">
      Previous
    </button>
    <button class="btn btn-egx mr-4" @click="cancelButtonFlow" v-if="this.stepsResponse.cancel_btn_url">
      {{this.stepsResponse['cancel_btn_lbl'] || 'Cancel &amp; Return to My Applications Page'}}
    </button>
    <button class="btn btn-egx mr-4" @click="addButtonFlow" v-if="this.stepsResponse.add_btn_url">
      {{this.stepsResponse['add_btn_label'] || 'Save &amp; Return to My Applications Page'}}
    </button>
    <button class="btn btn-egx mr-4" @click="nextStepFlow">
      {{this.stepsResponse['next_btn_label'] || 'Save &amp; Next Step'}}
    </button>
  </div>
</template>

<script>
import redirectPath from '@/shared/mixins/redirect-path';

export default {
  props: {
    stepsResponse: {
      type: Object,
      default: {},
    },
    dataSaveRequired: false
  },

  mixins: [redirectPath],

  computed: {
    displayPrevious(){
      return this.stepsResponse.hide_prev_btn === 'false' && Number(this.stepsResponse.step_num) > 1;
    }
  },

  methods: {
    prevButtonFlow(){
      this.$emit('navigate', 'ps');
    },

    nextStepFlow(){
      if (this.dataSaveRequired){
        this.$emit('save-data', 'nextStep');
      } else {
        this.$emit('navigate', 'ns');
      }
    },

    addButtonFlow(){
      if (this.dataSaveRequired){
        this.$emit('save-data', 'linkStep');
      } else {
        this.redirectPath(this.stepsResponse.add_btn_url);
      }
    },

    cancelButtonFlow(){
      this.redirectPath(this.stepsResponse.cancel_btn_url);
    },
  }
};
</script>

<style scoped lang="scss">
</style>
