<template>
  <div ref="contactComponent" class="m-4 text-center" v-if="isVisible" :class="{ 'loading-size': isVisible }">
    <font-awesome-icon
      :icon="['fa', 'circle-notch']"
      class="fa-spin m-auto spinner fa-4x egx-partner-color"
    />
  </div>
  <div :class="[{'content-box': !disableCard}, { 'no-card': disableCard }, 'mb-4']" v-else>
    <div class="card-wrapper">
      <div :class="['card-body', { 'no-card': disableCard }]">
        <!-- <span class="egx-partner-color">
          <h2 v-html="contactConfiguration.title" />
        </span>
        <div v-html="contactConfiguration.main_instructions" /> -->
        <div class="form-group row align-items-center mt-2">
          <div class="col-12 col-md-3 mb-2 mb-md-0">
            <label for="primaryContact" class="mb-0 sub-section-title" v-if="section !== 'user-profile'">Primary Contact</label>
            <label for="primaryContact" class="mb-0 sub-section-title" v-else>Contact List</label>
          </div>
          <div class="col-12 col-md-3 mb-2 mb-md-0">
            <select class="form-control question-list-item" id="primaryContact" v-model="selectedContactId" @change="populateForm">
              <option disabled selected value="">Select Contact</option>
              <option v-for="contact in contacts" :key="contact.id" :value="contact.id">{{ getContactName(contact.fields) }}</option>
            </select>
          </div>
          <div class="col-12 col-md-6 d-flex justify-content-md-end justify-content-center align-items-end">
            <button class="btn btn-egx" @click="addNewContact">Add New</button>
          </div>
        </div>
        <div v-if="showForm" class="mt-4">
          <span class="egx-partner-color">
            <h2>Contact Overview</h2>
          </span>
          <h3 class="egx-partner-color" v-html="contactHeader.title"/>
          <p v-html="contactHeader.subtitle" />
          <div class="form-group row" v-for="(field, index) in contactFields" :key="index">
            <label :for="field.name" class="col-3 col-form-label d-flex align-items-center flex-nowrap sub-section-title" v-if="field.name !== 'OtherPhone'">
              {{ field.name == "MobilePhone" ? "Other Phone" : field.label }}
              <span v-if="(field.required === 'true' && field.name !== 'OtherPhone') ? true : false" class="text-danger ml-1">*</span>
            </label>
            <div class="col-9 d-flex justify-content-center align-items-center">
              <validation-provider
                mode="lazy"
                :rules="addValidation(field)"
                v-slot="{ errors }"
                class="w-100"
              >
                <input
                  class="form-control question-list-item"
                  v-if="field.type !== 'TEXTAREA' && field.type !== 'EMAIL' && field.type !== 'PHONE'"
                  v-model="newContact[field.name]"
                  :placeholder="field.label"
                  :disabled="isReadOnly"
                  :required="field.required === 'true'"
                />
                <input
                  class="form-control question-list-item"
                  type="email"
                  name="email"
                  v-if="field.type === 'EMAIL'"
                  v-model="newContact[field.name]"
                  :placeholder="field.label"
                  :disabled="isReadOnly"
                  :required="field.required === 'true'"
                />
                <input
                  class="form-control question-list-item"
                  type="text"
                  v-if="field.type === 'PHONE' && field.name !== 'OtherPhone'"
                  v-model="newContact[field.name]"
                  :placeholder="field.name == 'MobilePhone' ? 'Other Phone' : field.label"
                  :disabled="isReadOnly"
                    @input="formatPhone(field.name)"
                />
                <textarea
                  v-else-if="field.type === 'TEXTAREA'"
                  class="form-control question-list-item"
                  :id="field.name"
                  v-model="newContact[field.name]"
                  :placeholder="field.label"
                  :disabled="isReadOnly"
                  :required="field.required === 'true'"
                ></textarea>
                <span class="msg-error text-danger">{{ errMsg = errors[0] }}</span>
              </validation-provider>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12 d-flex justify-content-center" v-if="isReadOnly">
              <button type="button" class="btn btn-egx" @click="enableEdit">Edit</button>
            </div>
            <div class="col-12 d-flex justify-content-center" v-else-if="!isReadOnly">
              <button type="button" class="btn btn-egx-white mr-2" @click="cancelForm" :disabled="isLoading">Cancel</button>
              <button type="submit" class="btn btn-egx" @click="createContact" :disabled="isLoading">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ isLoading ? 'Processing...' : (selectedContactId ? 'Update' : 'Create') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingScreen from './LoadingScreen.vue';
import mockData from '../mockData';

export default {
  components: {
    LoadingScreen,
  },
  props: {
    section: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showForm: false,
      newContact: {},
      selectedContactId: '',
      isReadOnly: false,
      loadingScreenContent: {
        title: {
          text: 'Loading Contacts',
          class: [],
        },
        description: {
          text: 'Please wait while we load the contacts',
          class: [],
          style: {},
        },
        spinner: {
          class: ['egx-partner-color'],
          style: {},
        },
        cta: {
          text: '',
          class: ['egx-partner-color'],
          style: {},
          path_name: '',
        },
      },
      isVisible: false,
      disableCard: false,
      isLoading: false,
      contactHeader: mockData.contactHeader,
      contactConfiguration: {},
    };
  },
  created() {
    this.isVisible = true;
    this.$store.dispatch('contacts/fetchContactsFields').then(() => {
      console.log('Fields created!!');
    });
    this.$store.dispatch('contacts/fetchContacts').then(() => {
      if (this.contacts.length === 1) {
        this.selectedContactId = this.contacts[0].id;
        this.populateForm();
      }
      console.log('Contacts loaded!!');
    });
    const project_id = this.$route.params.id;
    if(project_id) {
      this.$store.dispatch('fulfill_integrate/getProjectPrimaryContact', { project_id })
      .then((response) => {
        this.selectedContactId = response.contact_id;
        this.populateForm();
        this.isVisible = false;
      })
      .catch((error) => {
        this.isVisible = false;
        console.log(error);
      })
    } else {
      this.isVisible = false;
    }
  },
  computed: {
    contacts () {
      return this.$store.state.contacts.contacts || [];
    },
    contactFields() {
      const fields = this.updateFieldsAttributes(this.$store.state.contacts.contacts_fields)
      return fields || [];
    }
  },
  watch: {
    selectedContactId(newValue) {
      this.$emit('selected-contact-id', newValue);
    },
  },
  methods: {
    getContactName(fields) {
      let contactFields = fields || [];
      if (!fields) {
        const selectedContact = this.contacts.find(contact => contact.id === this.selectedContactId);
        contactFields = selectedContact.fields;
      }
      const firstName = contactFields.find(field => field.name === 'FirstName')?.value || '';
      const lastName = contactFields.find(field => field.name === 'LastName')?.value || '';
      return `${firstName} ${lastName}`;
    },
    populateForm() {
      const selectedContact = this.contacts.find(contact => contact.id === this.selectedContactId);
      if (selectedContact) {
        this.newContact = {};
        selectedContact.fields.forEach(field => {
          this.newContact[field.name] = field.value;
        });
        this.showForm = true;
        this.isReadOnly = true;
      }
    },
    addNewContact() {
      this.selectedContactId = '';
      this.newContact = {};
      this.contactFields.forEach(field => {
        this.newContact[field.name] = '';
      });
      this.showForm = true;
      this.isReadOnly = false;
    },
    cancelForm() {
      if (this.selectedContactId) {
        this.populateForm();
      } else {
        this.showForm = false;
      }
    },
    enableEdit() {
      this.isReadOnly = false;
    },
    createContact() {
      const isFormValid = this.contactFields.every(field => {
        if (field.required === 'true' && (field.name !== 'MobilePhone' && field.name !== 'OtherPhone')) {
          return this.newContact[field.name] && this.newContact[field.name].trim() !== '';
        }
        return true;
      });

      const emailField = this.contactFields.find(field => field.type === 'EMAIL');
      if (emailField) {
        const emailValue = this.newContact[emailField.name];
        if (emailValue) {
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailPattern.test(emailValue)) {
            this.$notify({
              group: 'notice',
              text: 'Please enter a valid email address.',
              duration: 5000,
            });
            return;
          }
        }
      }

      if (!isFormValid) {
        this.$notify({
          group: 'notice',
          text: 'Please fill in all required fields.',
          duration: 5000,
        });
        return;
      }

      this.isLoading = true;
      const formattedContact = {
        fields: Object.keys(this.newContact).map(key => ({
          name: key,
          value: this.newContact[key]
        }))
      };

      if (this.selectedContactId) {
        const updatePayload = {
          Id: this.selectedContactId,
          fields: formattedContact.fields
        };
        this.$store.dispatch('contacts/updateContact', updatePayload)
          .then(() => {
            this.$store.dispatch('contacts/fetchContacts').then(() => {
              this.populateForm();
              this.$notify({
                group: 'notice',
                text: 'Contact Updated successfully',
                duration: 5000,
              });
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isReadOnly = true;
            this.isLoading = false;
          });
      } else {
        this.$store.dispatch('contacts/createContact', formattedContact)
          .then((createdContact) => {
            this.selectedContactId = createdContact[0].id;
            this.showForm = true;
            this.isReadOnly = true;
            this.$notify({
              group: 'notice',
              text: 'Contact Created successfully',
              duration: 5000,
            });
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    selectPrimaryContact(contactId) {


    },
    updateFieldsAttributes(fields) {
      const fieldsToRemove = ['Email', 'OtherPhone', 'MobilePhone'];
      const updatedFields = fields.map(currentField => {
        const field = fieldsToRemove.find(fieldName => fieldName === currentField.name);
        if (field) {
          currentField = {
            ...currentField,
            required: 'false',
          };
        } else {
          currentField = {
            ...currentField,
          };
        }

        if (currentField.name === 'MailingPostalCode') {
          console.log(currentField);
          currentField = {
            ...currentField,
            label: 'Mailing Postal Code',
          };
        }
        return currentField;
      });
      return updatedFields;
    },
    addValidation(listItem) {
      const validationList = [];

      if (listItem.required === 'true') {
        validationList.push('required');
      }

      if (listItem.type === 'EMAIL') {
        validationList.push('email');
      }

      return validationList.join('|');
    },
    formatPhone(fieldName) {
      const phone = this.newContact[fieldName].replace(/\D/g, '');
      const formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      this.newContact[fieldName] = formattedPhone.substring(0, 12);
    },
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/stylesheets/components/project/contact.scss';
</style>
