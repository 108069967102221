<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-12">
        <div :class="['content-box', { 'no-card': disableCard }]">
          <div :class="['card-body', { 'no-card': disableCard }]">
            <div class="m-2 pl-3 text-center" v-if="isLoading">
              <font-awesome-icon
                :icon="['fa', 'circle-notch']"
                class="fa-spin m-auto spinner fa-5x egx-partner-color"
              />
            </div>
            <div v-else>
              <span class="egx-partner-color">
                <h2 v-html="applicationDetail.title" />
              </span>
              <div v-html="parsedMainInstructions" style="color: #6e7177;"/>
              <div v-for="group in sortedGroups" :key="group.sort_order" class="mt-4">
                <applicationDetails :group="group"/>
              </div>
              <div class="w-100 d-flex justify-content-center align-items-center">
                <button class="btn btn-egx" @click="returnToMyApplications()">
                  Return to My Applications Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationDetails from '../components/ApplicationDetails.vue';

export default {
  components: {
    ApplicationDetails,
  },
  data() {
    return {
      disableCard: false,
      applicationDetail: [],
      isLoading: false,
    };
  },

  computed: {
    sortedGroups() {
      return this.applicationDetail.groups.sort((a, b) => a.sort_order - b.sort_order);
    },
    parsedMainInstructions() {
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.applicationDetail.main_instructions, 'text/html');
      const images = doc.querySelectorAll('img');
      images.forEach(img => {
        const newImg = document.createElement('img');
        newImg.src = img.src;
        newImg.alt = img.alt;
        img.replaceWith(newImg);
      });
      return doc.body.innerHTML;
    }
  },

  created() {
    this.isLoading = true;
    this.$store.dispatch('fulfill_integrate/getProjectDetails', { project_id: this.$route.params.id })
    .then((response) => {
      this.applicationDetail = response.project;
      this.isLoading = false;
    }).catch((error) => {
      console.log(error)
    });
  },

  methods: {
    returnToMyApplications() {
      this.$router.push({ name: 'applicationsShowAll' });
    },
  },
}
</script>
